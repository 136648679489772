
import { ref, Ref, watch, computed, ComputedRef, reactive, onMounted } from 'vue';
import { db } from '../firebase';
import BaseButton from '@/components/BaseButton.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseValidationError from '@/components/BaseValidationError.vue';

export default {
  components: {
    BaseButton,
    BaseInput,
    BaseValidationError,
  },

  setup() {
    const submissionValidaionTriggered: Ref<boolean> = ref(false);
    const submissionTriggered: Ref<boolean> = ref(false);
    const successfulSubmission: Ref<boolean> = ref(false);
    const submissionError: Ref<boolean> = ref(false);

    const state = reactive({
      firstName: {
        formValue: '',
        invalid: false,
        formError: false,
      },
      lastName: {
        formValue: '',
        invalid: false,
        formError: false,
      },
      email: {
        formValue: '',
        invalid: false,
        formError: false,
      },
    });

    const firstName: ComputedRef<string> = computed(() => state.firstName.formValue);
    const lastName: ComputedRef<string> = computed(() => state.lastName.formValue);
    const email: ComputedRef<string> = computed(() => state.email.formValue);
    const showFirstNameError: ComputedRef<boolean> = computed(() => (submissionTriggered.value && state.firstName.formError) ?? false);
    const showLastNameError: ComputedRef<boolean> = computed(() => (submissionTriggered.value && state.lastName.formError) ?? false);
    const showEmailError: ComputedRef<boolean> = computed(() => (submissionTriggered.value && state.email.formError) ?? false);

    async function validateInput(): Promise<boolean> {
      const firstNameCheck = /^([A-Za-z])+$/;
      const lastNameCheck = /^([A-Za-z])+$/;
      const emailCheck = /([a-zA-Z0-9\-?\.?]+[\@]{1}[a-zA-Z0-9]+[\.]{1}(com|net|org|edu))/;

      state.firstName.formError = !firstNameCheck.test(firstName.value);
      state.lastName.formError = !lastNameCheck.test(lastName.value);
      state.email.formError = !emailCheck.test(email.value);

      if (state.firstName.formError || state.lastName.formError || state.email.formError) {
        return true;
      }

      return false;
    }

    function cancelRequest(): void {
      state.firstName.formValue = '';
      state.lastName.formValue = '';
      state.email.formValue = '';
    }

    async function submitRequest(): Promise<void> {
      submissionValidaionTriggered.value = true;
      await validateInput();
      if (!state.firstName.formError && !state.lastName.formError && !state.email.formError) {
        try {
          submissionTriggered.value = true;
          db.collection('accessRequest').add({
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value.trim().toLowerCase(),
            granted: false,
          }).then(async () => {
            submissionTriggered.value = false;
            state.firstName.formValue = '';
            state.lastName.formValue = '';
            state.email.formValue = '';
            state.firstName.formError = false;
            state.lastName.formError = false;
            state.email.formError = false;
            successfulSubmission.value = true;
            localStorage.setItem(`fmlDnrBsr`, JSON.stringify({ arc: true }));
          }).catch((err) => {
            submissionError.value = true;
          });
        } catch (err) {
          /** TODO: display theat there was a problem */
          console.error(`There was an issue submitting the form`);
        }

        return;
      }
    }

    // @ts-nocheck
    watch([firstName, lastName, email], async ([fName, lName, mail], [pfName, plName, pMail]) => {
      if (submissionValidaionTriggered.value) {
        validateInput();
      }
    });

    onMounted(() => {
      if (JSON.parse(localStorage.getItem(`fmlDnrBsr`) ?? `{}`)?.arc) {
        successfulSubmission.value = true;
      }
    })

    return {
      firstName,
      lastName,
      email,
      submitRequest,
      cancelRequest,
      submissionTriggered,
      showFirstNameError,
      showLastNameError,
      showEmailError,
      state,
      successfulSubmission,
    }
  }
}
