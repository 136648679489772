<template>
	<span :class="{
		'validation-error': true,
		'is--visible': visible,
	}">
		<slot />
	</span>
</template>

<script lang="ts">
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.validation-error {
  display: none;
  margin-top: 0.5rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--warn);
  letter-spacing: 1px;
  &.is {
    &--visible {
      display: block;
    }
  }
}
</style>