
import { onMounted, defineComponent, reactive } from 'vue';

export default defineComponent({
  props: {
    modelValue: String,
    inputType: {
      type: String,
      default: 'text',
    },
  },

  setup() {
    const state = reactive({
      isFocused: false,
    });

    return {
      state,
    }
  },
});
