<template>
  <section class="request o-page">
    <section class="request__info">
      <h1 class="request__heading o-heading">Request Access</h1>
      <h2 class="request__subheading">
        To request access please fill out the form below. You will be notified
        once your info has been confirmed. If you have already done so, head over to the <router-link to="sign-up">sign up/login page</router-link>
      </h2>
      <p class="request__disclaimer">
        It is important that you provide the email that you will use to sign up.
      </p>
    </section>
    <section class="request__form">
      <template v-if="!successfulSubmission">

        <BaseInput
          v-model="state.firstName.formValue"
          id="firstName"
        >
          First Name
          <template v-slot:error>
            <BaseValidationError :visible="state.firstName.formError">Please enter your First Name</BaseValidationError>
            <BaseValidationError>First Name must not contain special characters</BaseValidationError>
          </template>
        </BaseInput>
        <BaseInput
          v-model="state.lastName.formValue"
          id="lastName"
        >
          Last Name
          <template v-slot:error>
            <BaseValidationError :visible="state.lastName.formError">Please enter your First Name</BaseValidationError>
            <BaseValidationError>First Name must not contain special characters</BaseValidationError>
          </template>
        </BaseInput>
        <BaseInput
          v-model="state.email.formValue"
          id="email"
        >
          Email
          <template v-slot:error>
            <BaseValidationError :visible="state.email.formError">Please enter a valid email</BaseValidationError>
          </template>
        </BaseInput>
        <section class="request__actions">
        <BaseButton
          :disabled="submissionTriggered"
          class="request__actions-item"
          @click="submitRequest"
        >Submit Access Request</BaseButton>
        <BaseButton
          :undesired="true"
          class="request__actions-item"
          @click="cancelRequest"
        >Cancel & Clear Form</BaseButton>
      </section>
      </template>
      <p
        v-else
        class="request__submission-success o-copy"
      >
        Your request has been submitted! You will get an email once your request has been approved, with a link to sign in.
      </p>
    </section>
  </section>
</template>

<script lang="ts">
import { ref, Ref, watch, computed, ComputedRef, reactive, onMounted } from 'vue';
import { db } from '../firebase';
import BaseButton from '@/components/BaseButton.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseValidationError from '@/components/BaseValidationError.vue';

export default {
  components: {
    BaseButton,
    BaseInput,
    BaseValidationError,
  },

  setup() {
    const submissionValidaionTriggered: Ref<boolean> = ref(false);
    const submissionTriggered: Ref<boolean> = ref(false);
    const successfulSubmission: Ref<boolean> = ref(false);
    const submissionError: Ref<boolean> = ref(false);

    const state = reactive({
      firstName: {
        formValue: '',
        invalid: false,
        formError: false,
      },
      lastName: {
        formValue: '',
        invalid: false,
        formError: false,
      },
      email: {
        formValue: '',
        invalid: false,
        formError: false,
      },
    });

    const firstName: ComputedRef<string> = computed(() => state.firstName.formValue);
    const lastName: ComputedRef<string> = computed(() => state.lastName.formValue);
    const email: ComputedRef<string> = computed(() => state.email.formValue);
    const showFirstNameError: ComputedRef<boolean> = computed(() => (submissionTriggered.value && state.firstName.formError) ?? false);
    const showLastNameError: ComputedRef<boolean> = computed(() => (submissionTriggered.value && state.lastName.formError) ?? false);
    const showEmailError: ComputedRef<boolean> = computed(() => (submissionTriggered.value && state.email.formError) ?? false);

    async function validateInput(): Promise<boolean> {
      const firstNameCheck = /^([A-Za-z])+$/;
      const lastNameCheck = /^([A-Za-z])+$/;
      const emailCheck = /([a-zA-Z0-9\-?\.?]+[\@]{1}[a-zA-Z0-9]+[\.]{1}(com|net|org|edu))/;

      state.firstName.formError = !firstNameCheck.test(firstName.value);
      state.lastName.formError = !lastNameCheck.test(lastName.value);
      state.email.formError = !emailCheck.test(email.value);

      if (state.firstName.formError || state.lastName.formError || state.email.formError) {
        return true;
      }

      return false;
    }

    function cancelRequest(): void {
      state.firstName.formValue = '';
      state.lastName.formValue = '';
      state.email.formValue = '';
    }

    async function submitRequest(): Promise<void> {
      submissionValidaionTriggered.value = true;
      await validateInput();
      if (!state.firstName.formError && !state.lastName.formError && !state.email.formError) {
        try {
          submissionTriggered.value = true;
          db.collection('accessRequest').add({
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value.trim().toLowerCase(),
            granted: false,
          }).then(async () => {
            submissionTriggered.value = false;
            state.firstName.formValue = '';
            state.lastName.formValue = '';
            state.email.formValue = '';
            state.firstName.formError = false;
            state.lastName.formError = false;
            state.email.formError = false;
            successfulSubmission.value = true;
            localStorage.setItem(`fmlDnrBsr`, JSON.stringify({ arc: true }));
          }).catch((err) => {
            submissionError.value = true;
          });
        } catch (err) {
          /** TODO: display theat there was a problem */
          console.error(`There was an issue submitting the form`);
        }

        return;
      }
    }

    // @ts-nocheck
    watch([firstName, lastName, email], async ([fName, lName, mail], [pfName, plName, pMail]) => {
      if (submissionValidaionTriggered.value) {
        validateInput();
      }
    });

    onMounted(() => {
      if (JSON.parse(localStorage.getItem(`fmlDnrBsr`) ?? `{}`)?.arc) {
        successfulSubmission.value = true;
      }
    })

    return {
      firstName,
      lastName,
      email,
      submitRequest,
      cancelRequest,
      submissionTriggered,
      showFirstNameError,
      showLastNameError,
      showEmailError,
      state,
      successfulSubmission,
    }
  }
}
</script>

<style lang="scss" scoped>
.request {
  margin: 0 auto;
  &__actions {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    &-item {
      margin-top: 30px;
    }
  }
  &__submission-success {
    margin-top: 3rem;
  }
}
</style>
