<template>
	<div class="base-input">
		<label
			:for="$attrs.id"
			:class="{
        'base-input__label': true,
        'is--focused': state.isFocused,
      }"
		>
			<slot />
		</label>
		<input
			:name="$attrs.id"
			:id="$attrs.id"
			:value="modelValue"
			:type="inputType"
			:class="{
        'base-input__entry': true,
        'is--focused': state.isFocused,
      }"
      @focus="state.isFocused = true"
			@input="$emit('update:modelValue', $event.target.value)"
      @blur="state.isFocused = false"
		>
		<slot name="error" />
	</div>
</template>

<script lang="ts">
import { onMounted, defineComponent, reactive } from 'vue';

export default defineComponent({
  props: {
    modelValue: String,
    inputType: {
      type: String,
      default: 'text',
    },
  },

  setup() {
    const state = reactive({
      isFocused: false,
    });

    return {
      state,
    }
  },
});
</script>

<style lang="scss" scoped>
.base-input {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  text-align: left;
  &__label {
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
    letter-spacing: 1px;
    font-weight: 600;
    &.is {
      &--focused {
        color: var(--primaryAlt);
      }
    }
  }
  &__entry {
    width: 100%;
    height: 48px;
    border: 1px solid #b2b2b2;
    border-radius: 4px;
    border-width: 2px;
    font-size: 1.8rem;
    text-indent: 10px;
    outline: none;
    &.is {
      &--focused {
        border-color: var(--primaryAlt);
      }
    }
  }
  &:not(:first-of-type) {
    margin-top: 3rem;
  }
}
</style>